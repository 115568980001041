import * as React from "react"
import { Link, graphql } from "gatsby"
import Image from "gatsby-image";

import Layout from "../components/layout"
import Seo from "../components/seo"

/*eslint-disable*/ 
import { StaticImage } from 'gatsby-plugin-image'
import { makeStyles } from "@material-ui/core/styles"

// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import Typography from '@material-ui/core/Typography'

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import FormatAlignLeft from "@material-ui/icons/FormatAlignLeft";
import Favorite from "@material-ui/icons/Favorite";

// kit
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Badge from "components/Badge/Badge.js";
import Button from "components/CustomButtons/Button.js";

import Primary from "components/Typography/Primary.js";
import Warning from "components/Typography/Warning.js";
import Danger from "components/Typography/Danger.js";
import Info from "components/Typography/Info.js";
import Brown from "components/Typography/Brown.js";

import {
  warningColor,
  successColor,
} from "assets/jss/material-kit-pro-react.js";

import shingenStyle from "../style/kit_shingenStyle.js";
const useStyles_shingen = makeStyles(shingenStyle);

// img
import logo_l from "images/logo_l.svg"
import c from "images/tit1.png"
import h from "images/tit2.png"
import k from "images/tit4.png"

const useStyles = makeStyles((theme) => ({
  bukken_title:{
    marginBottom: '1em',
    paddingLeft: '1em',
    '& > div:first-child':{
      marginTop: '-2px',
    },
    '& h1':{ margin: '2px 0 0 0',},
  },
  description: { margin: '0 0 .5em 0', },
  bukkenImage:{
    margin: '0 auto 2em auto',
  },
  shosai:{
    '& h6':{ margin: ' 1em 0 .25em 0', },
    '& table': {
      border: 'solid .5em',
      width: '100%',
      borderColor: warningColor[8],
      background: warningColor[8],
      '& th':{
        padding: '.25em .5em .25em .5em',
        color: successColor[3],
        fontWeight: 'bold',
      },
      '& tbody':{
        '& tr:nth-child(2)':{
          '& span':{/**　賃料を赤 */
            color: '#cb2523',
            fontSize: '1.6em',
            fontWeight: 'bold',
          },
        },
        '& tr:nth-last-child(2)':{/** おすすめポイントの囲み */
          '& span':{
            color: '#cb2523',
            fontSize: '1em',
            display: 'inline-block',
            border: 'solid 1px #cb2523',
            padding: '.05em .5em .15em .5em',
            margin: '.15em',
          },
        },
        '& tr:last-child':{
          '& span':{
            width: '200px',
            height: 'auto',
          },
        },
      },
      '& td':{
        padding: '.25em .5em',
        '& b':{
          color: successColor[3],
          fontSize: '.9em',
        },
        '& div': {
          fontSize: '.8em',
          margin:'0',
          padding: '.1em .5em .2em .5em',
          marginBottom: '.5em',
          display: 'inline-block',
          borderLeft: 'double 3px',
          lineHeight: '1.2',
          borderColor: successColor[3],
          color: successColor[3],
        },
      },
    },
  }

}))

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  var titleImage = c
  const classes = useStyles_shingen();
  const cl = useStyles();
  var groupTit = "賃貸物件"

  if(post.frontmatter.group == "販売"){
    titleImage = h
    groupTit ="販売物件"
  }else if(post.frontmatter.group == "駐車場") {
    titleImage = k
    groupTit = "月極駐車場"
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={groupTit + "情報：" + post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <div>
        <Parallax image={require("images/header_back6.jpg").default} filter="dark" 
          className={classes.parallax}
        >
          <div className={classes.container}>
            <GridContainer justifyContent="center">
              <GridItem xs={12} className={classes.textCenter} >
                <h1 className={classes.title_2} id="logo_l">
                  <img src={logo_l} alert="有限会社 申源"/>
                </h1>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classes.main + " " + classes.main_s}>
          <div className={classes.container}>
            <div className={classes.shosaiTitle}>
              <Brown>
                <img src={titleImage} alt="" />
                <h4>
                  <span>{groupTit}情報</span>
                </h4>
              </Brown>
              <div className={cl.bukken_title}>
                <div>
                  {data.markdownRemark.frontmatter.tags.map((tag) => (
                    <Badge color="warning" key={tag} className={classes.badge}>{tag}</Badge>
                  ))}
                </div>
                <Primary><h1>{post.frontmatter.title}</h1></Primary>
              </div>
            </div>
            <article
              className={cl.shosai + " blog-post"}
              itemScope
              itemType="http://schema.org/Article"
            >
              <GridContainer>
                <GridItem md={10} lg={5} className={cl.bukkenImage}>
                  <Image fluid={data.markdownRemark.frontmatter.hero.childImageSharp.fluid} />
                </GridItem>
                <GridItem md={12} lg={7}>
                  <section
                    dangerouslySetInnerHTML={{ __html: post.html }}
                    itemProp="articleBody"
                  />
                </GridItem>
              </GridContainer>
            </article>
          </div>
        </div>
      </div>

    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query ($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        description
        group
        tags
        hero {
          childImageSharp {
            fluid(maxWidth: 1280) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
